import { type BoundingBoxDto, CountryCode } from '@ev/search-modules-api';

export const EV_FINANCE_URL =
  'https://www.ev-finance.de/finanzierung?utm_source=engelvoelkers_website&utm_medium=online_expose&utm_id=1';

export const SEO_INDEXED_COUNTRIES: ReadonlySet<CountryCode> = new Set([
  CountryCode.DE,
  CountryCode.ES,
  CountryCode.PT,
  CountryCode.AE,
  CountryCode.FR,
  CountryCode.US,
  CountryCode.CA,
  CountryCode.BS,
  CountryCode.VG,
  CountryCode.TC,
  CountryCode.KY,
  CountryCode.CR,
  CountryCode.MX,
  CountryCode.MF,
  CountryCode.PA,
  CountryCode.BZ,
  CountryCode.GR,
  CountryCode.LU,
  CountryCode.MU,
  CountryCode.LI,
  CountryCode.CH,
  CountryCode.IE,
  CountryCode.HR,
  CountryCode.CO,
  CountryCode.CL,
  CountryCode.UY,
  CountryCode.ZA,
  CountryCode.CZ,
  CountryCode.AD,
  CountryCode.HU,
  CountryCode.BE,
  CountryCode.NL,
  CountryCode.DK,
  CountryCode.IT,
  CountryCode.AT,
  CountryCode.HK,
]);

export const FINANCING_ENABLED_COUNTRIES: ReadonlySet<CountryCode> = new Set([
  CountryCode.DE,
  CountryCode.ES,
]);

export const MAP_ENABLED_COUNTRIES: ReadonlySet<CountryCode> = new Set([
  CountryCode.IT,
]);

// Italy bounding box (used if no placeId or bounding box is provided)
export const DEFAULT_MAP_BOUNDING_BOX: BoundingBoxDto = {
  northEast: {
    lat: 47.092,
    lng: 18.7975999,
  },
  southWest: {
    lat: 35.4897,
    lng: 6.6267201,
  },
};

export const DEFAULT_MAP_BOUNDING_BOX_EXTENDED: BoundingBoxDto = {
  southWest: {
    lat: 29.68855,
    lng: 0.5412801999999992,
  },
  northEast: {
    lat: 52.89315,
    lng: 24.883039800000002,
  },
};
